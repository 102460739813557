import "./index.scss";

import { Offline, Online } from "react-detect-offline";
import React, { useEffect, useState } from "react";

import Facebook from "./facebook";
import InstaStory from "./instastory";
import Instagram from "./instagram";
import { Loader } from "../common/";
import NavigationConfirm from "./confirm";
import TikTok from "./tiktok";
import Welcome from "./welcome";
import axios from "axios";
import config from "../util/config";
import { useData } from "../util/dataProvider";
import { useParams } from "react-router-dom";

export default function Test() {
  const { testId, testMode, debug } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [welcome, setWelcome] = useState(true);
  const [trankingText, setTrackingText] = useState(null);
  const [trackingIndex, setTrackingIndex] = useState(0);
  const { trackingArray } = useData();
  const [time, setTime] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 10);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (trackingArray) {
      if (trackingArray[trackingIndex + 1]) {
        setTrackingText(
          "id:" +
            trackingArray[trackingIndex + 1].item_id +
            " " +
            "-" +
            " " +
            trackingArray[trackingIndex + 1].action
        );
        setTrackingIndex(trackingIndex + 1);
      }
    }
  }, [trackingArray, trackingIndex, testMode]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 6000);
    const seconds = Math.floor((time / 100) % 60);
    const milliseconds = time % 100;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");
    const formattedMilliseconds = String(milliseconds).padStart(2, "0");

    let interval = Math.ceil(time / 100 / 5);

    return `${interval} / ${formattedMinutes}:${formattedSeconds}:${formattedMilliseconds}`;
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .post(`${config.apiUrl}/stream`, {
        test: testId,
        mode: testMode,
      })
      .then((response) => {
        console.log(response);
        setData(response.data);
        setIsLoading(false);
        if (!response.data.show_welcome) {
          setWelcome(false);
        }
      });
  }, [testId, testMode]);

  function content() {
    if (!data || isLoading) {
      return <Loader />; // Return Preloader
    } else if (welcome) {
      return <Welcome data={data.welcome_text} setoff={setWelcome} />;
    } else {
      switch (data.medium) {
        case "facebook":
          return <Facebook data={data} time={time} />;
        case "instagram":
          return <Instagram data={data} mode={"start"} time={time} />;
        case "tiktok":
          return <TikTok data={data} time={time} />;
        case "igstory":
          return <InstaStory data={data} time={time} />;
        case "igreel":
          return <Instagram data={data} mode={"reel"} time={time} />;
        default:
          return <>{JSON.stringify(data.medium)}</>;
      }
    }
  }
  return (
    <div>
      <NavigationConfirm />{" "}
      {debug == "debug" && (
        <Offline>
          <div className="offline">Achtung – Keine Internetverbindung</div>
        </Offline>
      )}
      {debug == "debug" && (
        <div className="debug-container">
          <div>Zeit: {formatTime(time)}</div>
          <div>{trankingText}</div>
        </div>
      )}
      {content()}
    </div>
  );
}
