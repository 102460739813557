import { useEffect } from "react";

const NavigationConfirm = () => {
  useEffect(() => {
    // Handler for the popstate event (triggered by back/forward navigation)

    // Push a new entry to the history stack
    window.history.pushState(null, "", window.location.pathname);
  }, []);

  return null; // This component doesn't render anything
};

export default NavigationConfirm;
