// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.scss";
import "./tiktok.scss";

import React, { useEffect, useRef, useState } from "react";
import { disableScroll, enableScroll } from "../../util/functions";
import { toFacebook, toIgStory, toInstagram } from "../../util/testTransition";
import { useHistory, useParams } from "react-router-dom";

import Footer from "./footer";
import LiveIMG from "./assets/live.svg";
import MusicAnimation from "./musikanimation";
import MusikTicker from "./musikticker";
import SearchIMG from "./assets/search.svg";
import Sidebar from "./sidebar";
import Slider from "./Slider";
import Swiper from "swiper";
import Video from "./Video";
import { Waypoint } from "react-waypoint";
import axios from "axios";
import config from "../../util/config";
import cookie from "react-cookies";
import { useData } from "../../util/dataProvider";

export default function TikTok({ data, time }) {
  const [started, setStarted] = useState(false);
  const { testId, testMode, debug } = useParams();
  const history = useHistory();
  const [callbackUrl, setCallbackUrl] = useState(cookie.load("callbackUrl"));
  const [tic, setTic] = useState(cookie.load("testuser_id"));
  const [sw, setSW] = useState(null);
  const [end, setEnd] = useState(false);
  const { finishing, trackingArray, settrackingArray, reset, setUserId } =
    useData();
  const endSwitch = useRef();

  useEffect(() => {
    if (testMode == "slideshow") {
      disableScroll();
    }
  }, [testId, testMode]);

  useEffect(() => {
    if (!started && callbackUrl) return;
    let swiper = new Swiper(".swiper-container", {
      direction: "vertical",
      autoHeight: true,
      spaceBetween: 0,
      pagination: {
        el: ".swiper-pagination",
      },
    });

    setSW(swiper);
    swiper.on("slideNextTransitionEnd", function () {
      let array = trackingArray;
      let now = Math.floor(Date.now());
      array.push({
        second: now,
        item_id: data.posts[swiper.activeIndex - 1].id,
        action: "post_end_focus",
      });

      const videos = document.getElementsByTagName("video");

      if (data.posts[swiper.activeIndex]) {
        videos[swiper.activeIndex - 1].pause();
        videos[swiper.activeIndex].play();
      } else {
        videos[swiper.activeIndex - 1].pause();
      }

      if (data.posts[swiper.activeIndex]) {
        array.push({
          second: now + 1,
          item_id: data.posts[swiper.activeIndex].id,
          action: "post_start_focus",
        });
      } else {
        array.push({
          second: now + 1,
          action: "scroll_bottom",
        });
      }
      settrackingArray([...array]);
    });

    swiper.on("slidePrevTransitionEnd", function () {
      let array = trackingArray;
      let now = Math.floor(Date.now());

      const videos = document.getElementsByTagName("video");
      if (data.posts[swiper.activeIndex + 1]) {
        videos[swiper.activeIndex + 1].pause();
        videos[swiper.activeIndex].play();
      } else {
        videos[swiper.activeIndex].play();
      }

      if (data.posts[swiper.activeIndex + 1]) {
        array.push({
          second: now,
          item_id: data.posts[swiper.activeIndex + 1].id,
          action: "post_end_focus",
        });
      } else {
      }
      array.push({
        second: now + 1,
        item_id: data.posts[swiper.activeIndex].id,
        action: "post_start_focus",
      });

      settrackingArray([...array]);
    });
    swiper.init();
  }, [started, callbackUrl]);

  useEffect(() => {
    setUserId(testId);
    let array = trackingArray;
    array.push({
      second: Math.floor(Date.now()),
      action: "start",
    });
    array.push({
      second: Math.floor(Date.now()),
      item_id: data.posts[0].id,
      action: "post_start_focus",
    });
    settrackingArray([...array]);
  }, []);

  function endTrack() {
    enableScroll();
    setEnd(true);
    let array = [...trackingArray];
    let now = Math.floor(Date.now());
    array.push({
      second: now,
      action: "scroll_bottom",
    });
    settrackingArray([...array]);
  }

  if (callbackUrl && !started) {
    return (
      <div
        id="tik-tok"
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button onClick={() => setStarted(true)} className="test-end">
          Test starten
        </button>
      </div>
    );
  }

  return (
    <div id="tik-tok">
      {testMode == "slideshow" && !end && (
        <div className="slideshow-cover"></div>
      )}
      <img src={SearchIMG} alt="Search" className="search" />
      <img src={LiveIMG} alt="Search" className="live" />

      <div className="tik-tok-title">
        <span style={{ color: "#ccc" }}>Folge ich </span>

        <b>Für dich</b>
      </div>

      <div className="swiper-container">
        <div className="swiper-wrapper">
          {data.posts &&
            data.posts.map((post, idx) => {
              if (post.inhalt.acf_fc_layout === "slider") {
                return (
                  <Slider
                    post={post}
                    idx={idx}
                    slideshow={testMode}
                    swiper={sw}
                    time={time}
                    key={idx + "tiktok-video"}
                  ></Slider>
                );
              }
              return (
                <Video
                  post={post}
                  idx={idx}
                  slideshow={testMode}
                  swiper={sw}
                  time={time}
                  key={idx + "tiktok-video"}
                ></Video>
              );
            })}
          <div className="swiper-slide">
            {callbackUrl ? (
              <button
                className="test-end"
                onClick={async function () {
                  const callbackUrlReplaced = callbackUrl
                    .replace("https://", "")
                    ?.replace("http//", "");
                  await reset();
                  window.location.href =
                    "https://" + callbackUrlReplaced + "?i_survey=" + tic;
                }}
              >
                Test beenden
              </button>
            ) : (
              <Waypoint
                scrollableAncestor={window}
                topOffset={"50px"}
                bottomOffset={"50px"}
                onEnter={() => endTrack()}
              >
                <div ref={endSwitch} id="tiktok-end">
                  {testMode === "normal" ? (
                    <button
                      className="test-end"
                      disabled={finishing}
                      onClick={async function () {
                        await reset();
                        await new Promise((r) => setTimeout(r, 300));
                        history.push(
                          `/test/${testId}/slideshow/${
                            debug == "debug" ? "debug" : "normal"
                          }`
                        );
                      }}
                    >
                      Diashow starten
                    </button>
                  ) : (
                    <button
                      className="test-end"
                      onClick={async function () {
                        await reset();
                        await new Promise((r) => setTimeout(r, 300));
                        history.push(
                          `/test/${testId}/normal/${
                            debug == "debug" ? "debug" : "normal"
                          }`
                        );
                      }}
                    >
                      Scroll modus starten
                    </button>
                  )}
                  {testMode !== "normal" && (
                    <button
                      className="test-end"
                      onClick={function () {
                        reset();
                        history.push(`/`);
                      }}
                    >
                      Test beenden
                    </button>
                  )}
                </div>
              </Waypoint>
            )}
          </div>
        </div>
        <div className="swiper-pagination"></div>
      </div>
      <Footer />
    </div>
  );
}
